<template>
  <div class="taskhallPage">
    <div class="app h5MaxWidth">
      <header class="isScrollTo noColor">
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Task Hall</h2>
          <div class="navskip" @click="showPop = true">
            <div class="openRule">
              <div class="icon display alignCenter justifyCenter radius500PX">
                <i class="iconfont icon-questionMark"></i>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="subject">
        <div class="invitationCard gongGeNAV">
          <ul class="display alignEnd alignStretch">
            <!-- <li class="flex1">
              <div>
                <h3 id="invitationCount">0</h3>
                <span>Registrations</span>
              </div>
            </li>
            <li class="flex1">
              <div>
                <h3 id="activeCount">0</h3>
                <span>Activate Qty</span>
              </div>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="main">
        <div class="taskList radius7PX" id="taskList">
          <ul>
            <li class="radius7PX" v-for="item in dataList" :key="item.id">
              <div class="display alignCenter">
                <div class="icon display alignCenter justifyCenter">
                  <img src="./../images/golds.png" alt="" />
                </div>
                <div class="gongGeNAV flex1">
                  <ol class="display alignEnd alignStretch">
                    <li class="flex1">
                      <div>
                        <p class="gradient">
                          <em class="currency">₹</em>{{ item.amount }}
                        </p>
                        <span>Reward amount</span>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="heading">
                <div class="titleExplain">
                  <p>{{ item.name }}</p>
                </div>
                <div class="experienceBar display alignCenter justifyDetween">
                  <div class="progressBar flex1 radius500PX">
                    <div
                      class="progress radius500PX"
                      id="invitationComplianceExtra5Progress"
                      :style="'width:' + item.icount / item.count + ' %'"
                    >
                      <div class="glow radius500PX"></div>
                    </div>
                  </div>
                  <div class="copies">
                    <p>{{ item.icount }} / {{ item.count }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showPop"
      position="left"
      :style="{ background: 'none' }"
    >
      <div class="promptMain et-02s radius15PX" style="left: -30px">
        <div class="promptTitleBar display alignCenter justifyDetween">
          <div
            class="roundOff radius50P display alignCenter justifyCenter"
            @click="showPop = false"
          >
            <i class="icon-cross iconfont"></i>
          </div>
        </div>
        <div class="promptCont">
          <div class="tipsBox">
            <div id="tips" class="tips">
              <div class="titleBar display alignCenter justifyDetween">
                <div>
                  <h3>Invitation description</h3>
                </div>
              </div>
              <div class="explain">
                <p id="invitationActivationMoney" class="length-limit-5">
                  When you invite your friends to register for this app and
                  successfully upgrade to VIP1, the platform will reward you
                  with 100 rupees
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPop: false,
      uid: "",
      type: 0,
      page: 1,
      dataList: [],
    };
  },
  components: {},
  methods: {},
  mounted() {
    this.getAjax(
      "api/task/dayindex",
      {
        type: this.type,
        page: this.page,
        uid: this.uid,
      },
      "post"
    ).then((res) => {
      this.dataList = res.data;
    });
  },
};
</script>

<style scoped lang="less">
.taskhallPage header .header {
  background-color: var(--a_1);
}

.main {
  position: relative;
}

.invitationCard {
  margin: 0;
  position: relative;
  z-index: 0;
}

.invitationCard.gongGeNAV ul li {
  padding: 0;
  text-align: left;
}

.invitationCard.gongGeNAV ul li h3,
.invitationCard.gongGeNAV ul li span {
  color: #fff;
}

.invitationCard.gongGeNAV ul li h3 {
  font-size: 30px;
}

.invitationCard .shade {
  z-index: -1;
}

.invitationCard .shade img {
  width: 230px;
  position: absolute;
  right: -30px;
  bottom: 0;
  opacity: 0.9;
}

#dailyAttendance,
#invitationActivation {
  display: none;
}

.taskhallPage .subject {
  padding: 60px 40px 80px;
  background-color: var(--a_1);
}

.taskhallPage .subject .title {
  width: 45%;
  top: 20px;
  left: 20px;
  position: absolute;
}

.taskhallPage .subject .title h3 {
  line-height: 1.2;
  font-size: 24px;
  color: #fff;
}

.taskhallPage .subject img {
  width: 100%;
}

.checkInBox,
.taskhallPage .taskList {
  margin: 10px;
}

.taskhallPage .taskList {
  top: -50px;
  position: relative;
  z-index: 1;
}

.weekCard {
  /* width: calc(100% + 10px);
    left: -5px;
    right: -5px; */
  padding: 10px;
  background-color: #fff;
  position: relative;
}

.weekCard .dateStatus p {
  text-align: center;
  font-size: 14px;
  color: var(--black3);
}

/*  has can lose  */
.weekCard .dateStatus.can p {
  font-weight: 700;
  color: var(--a_3);
}

.weekCard .dateStatus.has p {
  opacity: 0.6;
  color: var(--b_3);
}

.weekCard .dateStatus.lose p {
  opacity: 0.8;
  color: var(--black3);
}

.weekCard .reward > div {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
}

.weekCard .reward {
  text-align: center;
}

.weekCard .reward p {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
}

.weekCard .reward p i {
  font-size: 14px;
}

.weekCard .reward img {
  margin: 0 auto;
}

.weekCard .w1_6 ul > li {
  width: 33.33%;
  padding: 5px;
}

.weekCard .w1_6 ul > li > div {
  padding: 5px 0;
  background-color: var(--black4);
}

.weekCard .w1_6 .reward {
  margin: 0 0 10px;
  overflow: hidden;
  position: relative;
}

.weekCard .w1_6 .reward img {
  width: 80%;
  max-width: 60px;
  margin: 0 auto;
}

.weekCard .w7 {
  width: 30%;
  padding: 5px;
}

.weekCard .w7 > div {
  height: 100%;
  padding: 5px 0;
  text-align: center;
  background-color: var(--black4);
}

.weekCard .w7 .reward {
  position: relative;
}

.weekCard .w7 .reward > div {
  padding: 10px 0 0;
  position: relative;
}

.weekCard .w7 .reward p {
  font-size: 28px;
  color: var(--b_15);
}

.weekCard .w7 .reward img {
  width: 99%;
  max-width: 150px;
}

.weekCard .explain p {
  font-size: 14px;
  font-weight: 300;
  color: var(--black2);
}

.weekCard .explain p b {
  color: var(--accentColor);
}

.weekCard .explain {
  width: 100%;
  margin: 0;
  padding: 10px;
}

.taskhallPage .taskList .title {
  padding: 5px 10px;
}

.taskhallPage .taskList .title .icon {
  width: 50px;
}

.taskhallPage .taskList .title img {
  width: 100%;
}

.taskhallPage .taskList .title h3 {
  font-size: 22px;
  color: var(--accentColor);
}

.taskhallPage .taskList ul > li {
  margin: 10px 0;
  padding: 20px;
  background-color: #fff;
  position: relative;
  -moz-box-shadow: 10px -5px 20px 0px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 10px -5px 20px 0px rgba(0, 40, 14, 0.1);
  box-shadow: 10px -5px 20px 0px rgba(0, 40, 14, 0.1);
}

.taskhallPage .taskList ul > li .icon {
  width: 40px;
  overflow: hidden;
}

.taskhallPage .taskList ul > li .icon img {
  width: 100%;
}

.taskhallPage .taskList .titleExplain p {
  font-size: 18px;
  color: var(--black2);
}

.taskhallPage .taskList .actionBar {
  margin: 10px 0 0;
  position: relative;
}

.taskhallPage .taskList .statusBtn {
  padding: 5px 10px;
  font-size: 18px;
  color: var(--r_1);
  opacity: 0.5;
}

.taskhallPage .taskList .receiveBtn {
  color: #fff;
  background-color: var(--r_1);
  opacity: 1;
}

.taskhallPage .taskList li .heading {
  margin: 10px 0 0;
}

.taskhallPage .taskList li .explain {
  width: 100%;
  margin: 0;
}

.taskhallPage .taskList li .explain span {
  font-size: 14px;
  color: var(--black2);
}

.confirmAccept {
  background-color: var(--a_1);
}

.cancelAccept {
  background-color: var(--black3);
}

.receiveTasksHint .taskItem > div {
  margin: 0 0 5px;
}

.receiveTasksHint .taskItem .icon i {
  font-size: 36px;
}

.receiveTasksHint .actionBar {
  margin: 30px 0 0;
}

.taskList .gongGeNAV li {
  padding: 10px;
  text-align: left;
}

.taskList .gongGeNAV li p {
  line-height: 1;
  font-size: 28px;
  font-weight: 700;
  color: var(--black1);
}
</style>
